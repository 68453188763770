<template>
  <section class="lis-bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 text-center">
          <div class="heading pb-4">
            <h2>Choose Your Plan</h2>
            <h5 class="font-weight-normal lis-light">Discover &amp; connect with top-rated local businesses</h5>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 wow fadeInUp mb-5 mb-lg-0 text-center" style="visibility: visible; animation-name: fadeInUp;">
          <div class="price-table">
            <div class="price-header lis-bg-light lis-rounded-top py-4 border border-bottom-0 lis-brd-light">
              <h5 class="text-uppercase lis-latter-spacing-2">Basic</h5>
              <h1 class="display-4 lis-font-weight-500"><sup>$</sup> 39 <small>/mo</small></h1>
              <p class="mb-0">Basic User Membership</p>
            </div>
            <div class="border border-top-0 lis-brd-light bg-white py-5 lis-rounded-bottom">
              <ul class="list-unstyled lis-line-height-3">
                <li>One Time Fee</li>
                <li>One Listing</li>
                <li>90 Days Availability</li>
                <li>Featured In Search Results</li>
                <li>24/7 Support</li>
              </ul>
              <a href="#" class="btn btn-primary-outline btn-md lis-rounded-circle-50" data-abc="true" @click="showCheckoutModal(9.99, 1)"><i class="fa fa-shopping-cart pl-2"></i>Downgrade</a>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4 wow fadeInUp mb-5 mb-lg-0 text-center" style="visibility: visible; animation-name: fadeInUp;">
          <div class="price-table active">
            <div class="price-header lis-bg-primary py-4 text-white lis-rounded-top">
              <h5 class="text-uppercase lis-latter-spacing-2 text-white">Premium</h5>
              <h1 class="display-4 lis-font-weight-500 text-white"><sup>$</sup> 69 <small>/mo</small></h1>
              <p class="mb-0">Permium User Membership</p>
            </div>
            <div class="border border-top-0 lis-brd-light bg-white py-5 lis-rounded-bottom">
              <ul class="list-unstyled lis-line-height-3">
                <li>One Time Fee</li>
                <li>One Listing</li>
                <li>90 Days Availability</li>
                <li>Featured In Search Results</li>
                <li>24/7 Support</li>
              </ul> <a href="#" class="btn btn-primary btn-md lis-rounded-circle-50" data-abc="true"><i class="fa fa-shopping-cart pl-2"></i>Upgrade</a>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4 wow fadeInUp text-center" style="visibility: visible; animation-name: fadeInUp;">
          <div class="price-table">
            <div class="price-header lis-bg-light lis-rounded-top py-4 border border-bottom-0 lis-brd-light">
              <h5 class="text-uppercase lis-latter-spacing-2">business</h5>
              <h1 class="display-4 lis-font-weight-500"><sup>$</sup> 99 <small>/mo</small></h1>
              <p class="mb-0">Basic User Membership</p>
            </div>
            <div class="border border-top-0 lis-brd-light bg-white py-5 lis-rounded-bottom">
              <ul class="list-unstyled lis-line-height-3">
                <li>One Time Fee</li>
                <li>One Listing</li>
                <li>90 Days Availability</li>
                <li>Featured In Search Results</li>
                <li>24/7 Support</li>
              </ul> <a href="#" class="btn btn-primary-outline btn-md lis-rounded-circle-50" data-abc="true"><i class="fa fa-shopping-cart pl-2"></i>Your Plan</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal :show.sync="showPaymentModal">
      <h6 slot="header" class="modal-title" id="modal-title-default">Thanh toán</h6>
      <base-button block type="danger" @click="showMomoQRCode(1)">Momo</base-button>
      <div ref="paypal"></div>
    </modal>

    <modal :show.sync="showMomoModal" id="momo-modal">
      <h6 slot="header" class="modal-title">Quét mã thanh toán Momo</h6>
      <vue-qrcode v-if="momoQRCodeUrl" :value="momoQRCodeUrl" :options="{ width: 200 }"></vue-qrcode>
    </modal>
  </section>
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode';
import swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

export default {
  name: "PricingPlan",
  components: {
    VueQrcode
  },
  data() {
    return {
      showPaymentModal: false,
      showMomoModal: false,
      momoQRCodeUrl: '',
      loaded: false,
      paidFor: false,
      product: {
        price: 777.77,
        description: "leg lamp from that one movie",
        img: "./assets/lamp.jpg"
      },
      plans: []
    }
  },
  created() {
    let self = this;
    const channel = Echo.channel('payment-channel');
    console.log(channel);
    channel.listen('.momo-payment-event', function(data) {
      self.showMomoModal = false;
      console.log(data);

      if (data.status === true) {
        self.$store.commit("auth/setUserData", data.responseData);

        swal.fire({
          title: 'Thanh toán thành công!',
          icon: 'success'
        })
      } else {
        swal.fire({
          title: 'Thanh toán không thành công! Vui lòng thử lại',
          icon: 'error'
        })
      }
    });

    this.getPlans();
  },
  mounted: function() {
    const script = document.createElement("script");
    script.src =
      "https://www.paypal.com/sdk/js?client-id=" + process.env.VUE_APP_PAYPAL_API_KEY;
    // script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);
  },
  methods: {
    async getPlans() {
      let plans = await this.$store.dispatch('payment/getPlans');
      this.plans = plans;
    },
    setLoaded(price, planId) {
      let self = this;
      this.loaded = true;
      window.paypal
        .Buttons({
          createOrder: function(data, actions) {
            // This function sets up the details of the transaction, including the amount and line item details.
            return actions.order.create({
              purchase_units: [{
                amount: {
                  value: price
                }
              }]
            });
          },
          onApprove: async (data, actions) => {
            return actions.order.capture().then(async function(details) {
              // Confirm payment on serverside
              let response = await self.$store.dispatch('payment/confirmPaypalPayment', {
                amount: price,
                planId: planId,
                userId: self.$store.state.auth.user.data.id,
                apiResponse: details
              });

              // Update user's subscription status
              if (response.success) {
                self.$store.commit("auth/setUserData", response.user);
              }

              self.showPaymentModal = false;
              swal.fire({
                title: 'Thanh toán thành công!',
                icon: 'success'
              })
            });
          },
          onError: err => {
            self.showPaymentModal = false;
            swal.fire({
              title: 'Thanh toán không thành công! Vui lòng thử lại',
              icon: 'error'
            });
          },
          style: {
            layout:  'horizontal',
            shape:   'rect',
            label:   'paypal'
          }
        })
        .render(this.$refs.paypal);
    },
    showCheckoutModal(price, planId) {
      this.showPaymentModal = true;
      this.setLoaded(price, planId);
    },
    async showMomoQRCode(planId) {
      this.showPaymentModal = false;
      this.showMomoModal = true;
      this.$preloaders.open({container: '#momo-modal'});
      let response = await this.$store.dispatch('payment/getMomoQRCode', {amount: 1000, planId: planId});
      if (response.url) {
        this.momoQRCodeUrl = response.url;
        this.$preloaders.close({container: '#momo-modal'});
      }
    }
  }
}
</script>

<style scoped>
.lis-bg-light {
  background-color: #00000005
}

section {
  padding-top: 6.25em;
  padding-bottom: 6.25em;
  position: relative
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important
}

h2 {
  font-size: 2rem
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #222222;
  margin: 0 0 15px;
  font-family: "Poppins", sans-serif;
  line-height: 1.75rem
}

.lis-light {
  color: #707070
}

.font-weight-normal {
  font-weight: 400 !important
}

h5 {
  font-size: 1.25rem
}

.price-table {
  -webkit-transition: 0.3s ease
}

.lis-brd-light {
  border-color: #dadada !important
}

.lis-rounded-top {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px
}

.lis-bg-light {
  background-color: #f7f7f7
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important
}

.lis-latter-spacing-2 {
  letter-spacing: 2px
}

.text-uppercase {
  text-transform: uppercase !important
}

.lis-font-weight-500 {
  font-weight: 500
}

.display-4 {
  font-size: 3.5rem
}

.price-table sup {
  top: -1.5em
}

.price-table sup,
.price-table small {
  font-size: 1.25rem
}

.price-table small {
  font-size: 1.25rem
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important
}

sup {
  position: relative;
  font-size: 75%;
  line-height: 0
}

.lis-brd-light {
  border-color: #2dce89 !important
}

.bg-white {
  background-color: #fff !important
}

.py-5 {
  padding-bottom: 3rem !important
}

.lis-line-height-3 {
  line-height: 3 !important
}

.list-unstyled {
  padding-left: 0;
  list-style: none
}

.lis-line-height-3 {
  line-height: 3 !important
}

.btn-primary-outline {
  background-color: transparent;
  color: #2dce89 !important;
  border-color: #2dce89
}

.btn {
  color: #fff;
  padding: .5rem 1.3rem;
  font-size: 1rem;
  word-wrap: break-word
}

.lis-rounded-circle-50 {
  border-radius: 50px
}

.pl-2,
.px-2 {
  padding-right: .5rem !important
}

.btn-primary-outline:hover,
.btn-primary-outline:focus {
  background-color: #2dce89;
  border-color: #2dce89;
  color: #fff !important
}

.lis-bg-primary {
  background-color: #2dce89
}

.btn-primary {
  background-color: #2dce89;
  border-color: #2dce89
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: #2dce89;
  border-color: #2dce89
}

.price-table.active {
  transform: scale(1.045);
  -webkit-transform: scale(1.045)
}

.price-table {
  -webkit-transition: 0.3s ease
}

.lis-rounded-bottom {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px
}
</style>
