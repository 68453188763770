<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <h1 class="mt-4 text-center">Đăng nhập</h1>
            <div class="card-body px-lg-5 py-lg-5">
              <base-alert type="danger" v-if="errMsg">
                <strong>Lỗi!</strong> {{ errMsg }}
              </base-alert>
              <validation-observer
                v-slot="{ handleSubmit }"
                ref="formValidator"
              >
                <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input
                    alternative
                    class="mb-3"
                    name="Email"
                    :rules="{ required: true, email: true }"
                    prepend-icon="ni ni-email-83"
                    placeholder="Email"
                    v-model="model.email"
                  >
                  </base-input>

                  <base-input
                    alternative
                    class="mb-3"
                    name="Password"
                    :rules="{ required: true, min: 6 }"
                    prepend-icon="ni ni-lock-circle-open"
                    type="password"
                    placeholder="Mật khẩu"
                    v-model="model.password"
                  >
                  </base-input>

                  <base-checkbox v-model="model.rememberMe"
                    >Remember me</base-checkbox
                  >
                  <div class="text-center">
                    <base-button
                      type="primary"
                      native-type="submit"
                      class="my-4"
                      :loading="isLoading"
                      >Đăng nhập</base-button
                    >
                  </div>
                </form>
              </validation-observer>
            </div>
          </div>
          <!-- <div class="row mt-3">
            <div class="col-6">
              <router-link to="/dashboard" class="text-light"><small>Quên mật khẩu?</small></router-link>
            </div>
            <div class="col-6 text-right">
              <router-link to="/register" class="text-light"><small>Tạo tài khoản mới</small></router-link>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import request from "@/utils/request";

export default {
  data() {
    return {
      model: {
        email: "",
        password: "",
        rememberMe: false,
      },
      errMsg: "",
      isLoading: false,
    };
  },
  created() {
    if (this.isLogged) {
      this.$router.push({ name: "Dashboard" });
    }
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?render=6LclxccZAAAAABcPEDZLiNshh18vVKwzocsLMCBV"
    );
    document.head.appendChild(recaptchaScript);
  },
  computed: {
    ...mapGetters({
      isLogged: "auth/isLogged",
    }),
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      this.errMsg = "";
      let self = this;

      grecaptcha.ready(function () {
        grecaptcha
          .execute("6LclxccZAAAAABcPEDZLiNshh18vVKwzocsLMCBV", {
            action: "submit",
          })
          .then(function (token) {
            axios
              .get(process.env.VUE_APP_API_SANCTUM_URL + "/sanctum/csrf-cookie")
              .then((response) => {
                self.$store
                  .dispatch("auth/login", {
                    email: self.model.email,
                    password: self.model.password,
                    recaptcha_token: token,
                  })
                  .then(() => {
                    self.$router.push("/dashboard");
                  })
                  .catch((err) => {
                    self.errMsg = err.response.data.message;
                    self.isLoading = false;
                  });
              });
          });
      });
    },
  },
};
</script>
