<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Nhập link</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <base-alert type="danger" v-if="errText !== ''">
            <strong>Lỗi!</strong> {{ errText }}
          </base-alert>

          <base-alert type="success" v-if="submitSuccess">
            <strong>Urls đã được upload thành công!</strong>
          </base-alert>
          <!-- Form groups used in grid -->
          <div class="row">
            <div class="col-md-12">
              <base-input v-model="inputs.packageName" label="Tên(không bắt buộc)"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <base-input label="Nhập link">
                <textarea
                  v-model="inputs.urls"
                  :class="[errors.get('urls') ? 'is-invalid' : '', 'form-control']"
                  rows="10"
                  :disabled="isLoading"
                ></textarea>
                <div v-if="errors.get('urls')" class="invalid-feedback"> {{ errors.get('urls') }} </div>
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <base-input label="Dripfeed">
                <select class="form-control" v-model="inputs.dripfeed">
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-control-label">Upload file (.txt)</label>
                <file-input @change="filesChange" accept=".txt"></file-input>
                <div v-if="errors.get('file')" class="invalid-feedback" :style="{display: errors.get('file') ? 'block' : 'none'}"> {{ errors.get('file') }} </div>
              </div>
            </div>
          </div>
          <base-button type="primary" native-type="submit" @click.prevent="onSubmit" :loading="isLoading">Submit</base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileInput from '@/components/Inputs/FileInput'
import _ from 'lodash';
import Errors from '@/utils/error';

export default {
  name: "SubmitLinks",
  components: {
    FileInput
  },
  data() {
    return {
      inputs: {
        file: null,
        urls: '',
        packageName: '',
        dripfeed: 1
      },
      errors: new Errors(),
      errText: '',
      submitSuccess: false,
      isLoading: false
    };
  },
  computed: {
    hasErrors() {
      return !_.isEmpty(this.errors.errors);
    }
  },
  methods: {
    filesChange(files) {
      this.inputs.file = files
    },
    resetForm() {
      this.inputs = {
        file: null,
        urls: '',
        packageName: '',
        dripfeed: 1
      }
    },
    onSubmit() {
      let self = this;
      this.submitSuccess = false;
      this.errors = new Errors();
      this.errText = '';
      this.isLoading = true;

      let formData = new FormData();
      formData.append("file", this.inputs.file ? this.inputs.file[0] : null);
      formData.append("urls", this.inputs.urls);
      formData.append("name", this.inputs.packageName);
      formData.append("dripfeed", this.inputs.dripfeed);

      this.$store.dispatch('links/submitLinks', formData)
        .then(() => {
          this.resetForm();
          this.submitSuccess = true;
          this.isLoading = false;
        })
        .catch(err => {
          if (err.response.status === 422) {
            self.errors.record(err.response.data);
          }

          if (err.response.status === 503 || err.response.status === 500) {
            self.errText = 'Submit link không thành công xin vui lòng thử lại!'
          } else {
            self.errText = 'Thông tin nhập vào không đúng, xin thử lại!';
          }

          self.isLoading = false;
        });
    }
  }
}
</script>

<style scoped>

</style>
