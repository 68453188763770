<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0">Danh sách link</h3>
          </template>

          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select
                class="select-primary pagination-select"
                v-model="listQuery.limit"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <div>
                <base-input v-model="listQuery.search"
                            prepend-icon="fas fa-search"
                            placeholder="Tìm kiếm...">
                </base-input>
              </div>
            </div>

            <el-table :data="tableData"
                      row-key="id"
                      header-row-class-name="thead-light"
                      v-loading="listLoading"
                      @sort-change="sortChange"
                      @selection-change="selectionChange">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>
              <el-table-column min-width="180px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                  <base-button
                    @click.native="handleView($index, row)"
                    class="view btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="text-white ni ni-zoom-split-in"></i>
                  </base-button>

                  <base-button
                    @click.native="handleDelete($index, row)"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="text-white ni ni-fat-remove"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Tổng cộng: {{ pagination.total }}
              </p>

            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>

    <modal :show.sync="showDetailModal" size="lg" id="detail-modal" modalContentClasses="detail-modal-content" bodyClasses="detail-modal-body">
      <h6 slot="header" class="modal-title">Danh sách link đã upload</h6>
      <ul>
        <li :key="url" v-for="url in urls">{{ url }}</li>
      </ul>
    </modal>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from '@/components';
import swal from 'sweetalert2';

export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      tableColumns: [
        {
          prop: 'name',
          label: 'Tên',
          minWidth: 160,
          sortable: true
        },
        {
          prop: 'numbers',
          label: 'Số lượng(Dripfeed)',
          minWidth: 220,
          sortable: true
        },
        {
          prop: 'created_at',
          label: 'Ngày tạo',
          minWidth: 150,
          sortable: true
        }
      ],
      tableData: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0
      },
      listQuery: {
        page: 1,
        limit: 10,
        search: undefined,
        sortBy: 'created_at',
      },
      listLoading: false,
      showDetailModal: false,
      urls: []
    };
  },
  created() {
    this.getList();
  },
  watch: {
    'listQuery.limit': {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  methods: {
    async getList() {
      this.listLoading = true;
      this.listQuery.page = this.pagination.currentPage;
      const { data, pagination } = await this.$store.dispatch('links/fetchLinks', this.listQuery);

      this.tableData                = data;
      this.pagination.total         = pagination.total;
      this.pagination.currentPage   = pagination.from;
      this.listQuery.page           = pagination.from;
      this.listLoading              = false;
    },
    handleDelete(index, row) {
      swal.fire({
        title: 'Chú ý',
        text: `Xác nhận xoá dòng này?`,
        icon: 'warning',
        showCancelButton: true,
        // confirmButtonClass: 'btn btn-success btn-fill',
        // cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Huỷ',
        // buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row)
            .then(response => {
              swal.fire({
                title: response.status ? 'Thành công!' : 'Lỗi!',
                text: response.message,
                icon: response.status ? 'success' : 'error',
                // buttonsStyling: false,
                // customClass: {
                //   confirmButton: response.status ? 'btn btn-success btn-fill' : 'btn btn-danger btn-fill'
                // }
              });
            });

          this.getList();
        }
      });
    },
    async deleteRow(row) {
      return await this.$store.dispatch('links/deleteLink', row.id);
    },
    handleView(index, row) {
      this.showDetailModal =true;
      this.urls = row.urls;
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    },
    sortChange({ prop, order }) {
      if (prop) {
        this.tableData.sort((a, b) => {
          let aVal = a[prop]
          let bVal = b[prop]
          if (order === 'ascending') {
            return aVal > bVal ? 1 : -1
          }
          return bVal - aVal ? 1 : -1
        })
      } else {
        this.tableData.sort((a, b) => {
          return a.id - b.id
        })
      }
    }
  }
};
</script>
<style>
.no-border-card .card-footer{
  border-top: 0;
}
.detail-modal-content {
  height: 80vh;
}

.detail-modal-body {
  /* 100% = dialog height, 120px = header + footer */
  max-height: calc(100% - 120px);
  overflow-y: scroll;
}
</style>
